import React from 'react';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import TeamPlayer from '../images/team_spirit.svg';
import Programming from '../images/programming.svg';
import AsideWork from '../images/nature_fun.svg';
import WhatIDo from '../images/pair_programming.svg';
import NextChallenge from '../images/adventure.svg';
import ContactMe from '../images/video_call.svg';

const Header = styled.header`
  width: 100%;
  height: 900px;
  position: relative;
  padding: 1.75rem;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    height: 700px;
  }
`;

const Hero = styled(Flex)`
  height: 100%;
  text-align: center;
  h1 {
    letter-spacing: 0.2rem;
    line-height: 4.5rem;
  }
  h3 {
    font-family: ${props => props.theme.fontFamily.body};
    margin-top: 2rem;
    font-size: 1.85rem;
    font-weight: 400;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    h1 {
      line-height: 3.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    h1 {
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
  }
`;

const Wrapper = styled(Flex)`
  max-width: ${props => props.theme.maxWidth};
`;

const PrimaryBG = styled.section`
  background: ${props => props.theme.colors.primaryDark};
  color: ${props => props.theme.colors.textInvert};
  h1,
  h2,
  h3,
  h4 {
    color: ${props => props.theme.colors.textInvert};
  }
`;

const ServiceImage = styled.div`
  flex-basis: calc(99.9% * 4 / 8 - 3rem);
  max-width: calc(99.9% * 4 / 8 - 3rem);
  width: calc(99.9% * 4 / 8 - 3rem);
  text-align: center;
  margin-left: 3rem;
  margin-right: 3rem;
  img {
    width: 98%;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    img {
      width: 50%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    img {
      width: 75%;
    }
  }
`;

const ServiceText = styled.div`
  flex-basis: calc(99.9% * 4 / 8 - 3rem);
  max-width: calc(99.9% * 4 / 8 - 3rem);
  width: calc(99.9% * 4 / 8 - 3rem);
  padding-left: 3rem;
  padding-right: 3rem;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
  ol,
  ul {
    list-style: none;
    margin-left: 0;
  }
  li:before {
    content: '－';
    padding-right: 15px;
  }
`;

const Details = () => (
  <Layout>
    <Header>
      <Hero justifyContent="center" alignItems="center" flexDirection="column">
        <h1>
          I'm Ronny.
          <br />A seasoned product and engineering lead
        </h1>
        <h3>
          ...who helps teams to build great products and has been
          <br />
          working hands-on across the stack for almost two decades
        </h3>
      </Hero>
    </Header>

    <PrimaryBG>
      <Wrapper flexDirection="column" p={4} mx="auto">
        <Flex w={1} py={3} justifyContent="space-between" flexWrap="wrap">
          <ServiceImage>
            <img src={Programming} alt="Systems Engineer" />
          </ServiceImage>
          <ServiceText>
            <h2>I Am A Systems Engineer</h2>
            <p>
              I’m an engineer who loves to architect and develop software, processes and cloud infrastructure which all
              together I would call systems. I am as excited to build new systems as I am about running, troubleshooting
              and optimizing complex production systems. Working hands-on is as important to me as doing research about
              new technologies, analyzing their business potentials and proposing use cases. Since about a decade I'm
              also leading projects, product development and teams.
            </p>
          </ServiceText>
        </Flex>
      </Wrapper>
    </PrimaryBG>

    <Wrapper flexDirection="column" p={4} mx="auto">
      <Flex w={1} py={3} justifyContent="space-between" flexDirection="row-reverse" flexWrap="wrap">
        <ServiceImage>
          <img src={WhatIDo} alt="What I Do" />
        </ServiceImage>
        <ServiceText>
          <h2>What I Do</h2>
          <p>
            I work for an awesome remote-only company called Digication which provides ePortfolio and assessment
            solutions to institutions in the US. I’m the CTO here which sounds fancy but really means that I help our
            engineers to develop great and reliable products. I’m also in charge of all things cloud and infrastructure
            which is great because I value “owning crucial parts of a business” and the high level of autonomy and
            responsibility that comes with it. About 75% of my time I'm working hands-on running our systems and
            developing new ones.
          </p>
        </ServiceText>
      </Flex>
    </Wrapper>

    <PrimaryBG>
      <Wrapper flexDirection="column" p={4} mx="auto">
        <Flex w={1} py={3} justifyContent="space-between" flexWrap="wrap">
          <ServiceImage>
            <img src={TeamPlayer} alt="Team Player" />
          </ServiceImage>
          <ServiceText>
            <h2>I Am A Team Player</h2>
            <p>
              I love to work as part of a team, learn from my team mates, provide mentorship and guidance so that
              everyone at the team is able to do the best work she possibly can do. I’m self motivated, hold myself
              accountable for my work and commitments. Although I always work with a sense of urgency, I don’t like
              cutting corners or see technical debt growing too much. These are attributes that I truly appreciate in
              everyone.
            </p>
          </ServiceText>
        </Flex>
      </Wrapper>
    </PrimaryBG>

    <Wrapper flexDirection="column" p={4} mx="auto">
      <Flex w={1} py={3} justifyContent="space-between" flexDirection="row-reverse" flexWrap="wrap">
        <ServiceImage>
          <img src={AsideWork} alt="Aside From Work" />
        </ServiceImage>
        <ServiceText>
          <h2>Aside From Work</h2>
          <p>
            I enjoy life away from work as well. About four years ago my wife and I sold pretty much everything we
            owned, packed what was left and moved into the woods in Sweden (surprise: with a 250MBit fiber optic
            connection). We enjoy being active with our two huskies, we like sledding with them in winter, we are
            building all kinds of things and work on a sustainable style of living with gardening, growing animals,
            keeping bees and (really German-like) brewing beer.
          </p>
        </ServiceText>
      </Flex>
    </Wrapper>

    <PrimaryBG>
      <Wrapper flexDirection="column" p={4} mx="auto">
        <Flex w={1} py={3} justifyContent="space-between" flexWrap="wrap">
          <ServiceImage>
            <img src={NextChallenge} alt="Ready For The Next Challenge" />
          </ServiceImage>
          <ServiceText>
            <h2>The Next Challenge</h2>
            <p>
              I didn't make this application lightly, and have spent some time considering whether or not to really go
              for this. I love working with people that value transparency &amp; trust and are open for feedback; who
              respect each other and create an enjoyable work environment; and all that while building a fast growing
              business with many challenges ahead.
            </p>
          </ServiceText>
        </Flex>
      </Wrapper>
    </PrimaryBG>

    <Wrapper flexDirection="column" p={4} mx="auto">
      <Flex w={1} py={3} justifyContent="space-between" flexDirection="row-reverse" flexWrap="wrap">
        <ServiceImage>
          <img src={ContactMe} alt="Let's Talk" />
        </ServiceImage>
        <ServiceText>
          <h2>
            Let's talk.
            <br />
            Ronny
          </h2>
          <h3>
            <a href="https://linkedin.com/in/ronnyunger" target="_blank" rel="noopener noreferrer">
              linkedin.com/in/ronnyunger
            </a>
          </h3>
        </ServiceText>
      </Flex>
    </Wrapper>
    <Footer />
  </Layout>
);

export default Details;
